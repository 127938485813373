import { useApi } from "lib/apiFetcher";



//@ts-ignore
export const useAuthV2LoginApi = () => useApi("post", "/auth/v2/login");
//@ts-ignore
export const useAuthAccountApi = () => useApi("post", "/auth/v2/account-chooser");
//@ts-ignore
export const useOauthTokenApi = () => useApi("post", "/oauth/gk/token");

export const useAuthRegisterApi = () => useApi("post", "/auth/register");
export const useAuthSocialApi = () => useApi("post", "/auth/social");
export const useAuthLogoutApi = () => useApi("post", "/auth/logout");
export const useAuthSendPasswordResetApi = () =>
  useApi("post", "/auth/password/email");

export const useAuthPasswordResetApi = () =>
  useApi("post", "/auth/password/reset");

export const useAuthMoodleApi = () =>
  //@ts-ignore
  useApi("post", "/auth/login-register");

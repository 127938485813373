import { createContext, useContext } from "react";

export const isDeviceDesktop = (userAgent: string) =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  ) === false;

export const IsDesktopContext = createContext(false);

export const useIsDesktop = () => {
  return useContext(IsDesktopContext);
};

import React from "react";

type Props = {
  size?: number;
  color?: string;
};

export const IconCheck: React.FC<Props> = ({
  size = 20,
  color = "#318A4E",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8047 4.19541C14.0651 4.45596 14.0651 4.87838 13.8047 5.13893L6.47795 12.4713C6.21778 12.7316 5.79603 12.7318 5.53561 12.4717L2.19572 9.13587C1.93512 8.87558 1.93471 8.45315 2.1948 8.19235C2.45489 7.93155 2.877 7.93113 3.13761 8.19142L6.00608 11.0564L12.8619 4.19541C13.1223 3.93486 13.5444 3.93486 13.8047 4.19541Z"
        fill={color}
      />
    </svg>
  );
};

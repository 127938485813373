import { sha1 } from "js-sha1";
import { isBrowser } from "./ssr";

// example props domain=> 'domain=gokampus.com'
export const setCookie = (
  cookieName: string,
  cookieValue: string,
  expDays: number,
  domain?: string
) => {
  var d = new Date();
  d.setTime(d.getTime() + expDays * 24 * 60 * 60 * 1000);

  // add 7 hours
  d.setHours(d.getHours() + 7);
  var expires = "expires=" + d.toUTCString();

  if (domain) {
    document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/" + ";" + domain;
  }

  document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
};

export const getCookie = (cookieName: string) => {
  function escape(s: string) {
    return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, "\\$1");
  }
  var match =
    typeof window !== "undefined" &&
    document.cookie.match(
      RegExp("(?:^|;\\s*)" + escape(cookieName) + "=([^;]*)")
    );
  return match ? match[1] : null;
};

export const deleteCookie = (cookieName: string) => {
  document.cookie = cookieName + "=; Max-Age=0";
};

export const generateParams = (objectValue: any) => {
  let paramsLikeQueryString = [];

  Object.entries(objectValue).forEach((a) => {
    const query = `${a[0]}=${a[1]}`;

    paramsLikeQueryString.push(query);
  });

  return paramsLikeQueryString.join("&");
};

export const getQueryStringParams = (query: string) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
        .split("&")
        .reduce((params, param) => {
          let [key, value] = param.split("=");
          params[key] = value
            ? decodeURIComponent(value.replace(/\+/g, " "))
            : "";
          return params;
        }, {})
    : {};
};

export const setParamsAsCookies = (paramQuery1: any, paramQuery2: any) => {
  // any spesific cookie type UTM
  if (paramQuery1 && Object.keys(paramQuery1).length > 0) {
    for (let [k, v] of Object.entries(paramQuery1)) {
      //@ts-ignore
      setCookie(`${k}_gk`, v, 7);
    }
  } else if (paramQuery2 && Object.keys(paramQuery2).length > 0) {
    for (let [k, v] of Object.entries(paramQuery2)) {
      //@ts-ignore
      setCookie(`${k}_gk`, v, 7);
    }
  }
};

export const getUTMActived = () => {
  let cookies = {
    utm_source_gk: null,
    utm_medium_gk: null,
    utm_campaign_gk: null,
  };

  let cookiesWithoutStr = {
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
  };

  Object.keys(cookiesWithoutStr).forEach(function (a, idx) {
    Object.keys(cookies).forEach(function (key, index) {
      if (key.includes(a)) {
        cookiesWithoutStr[a] = getCookie(key);
      }
    });
  });

  const isAnyQueryCookie =
    cookiesWithoutStr &&
    Object.entries(cookiesWithoutStr).every(([key, value]) => value !== null);

  if (isAnyQueryCookie) {
    return cookiesWithoutStr;
  } else {
    return null;
  }
};

export const generateShareContent = (value: any, isBlog: boolean) => {
  let key = isBlog ? "title" : "name";
  let campaign = isBlog ? "blog-share" : "lms-share";
  let path = isBlog ? "blog" : "course";

  // Function to generate the WhatsApp sharing URL
  const generateWhatsAppURL = () => {
    const baseUrl = "https://api.whatsapp.com/send";
    const message = `${value[key]}. Yuk Belajar GRATIS bareng-bareng!`;
    const url = isBrowser()
      ? `https://${window.location.host}/${path}/${value.slug}`
      : ``;

    // Encode the message and URL
    const encodedMessage = encodeURIComponent(message);
    const encodedUrl = encodeURIComponent(url);

    // Construct the final WhatsApp sharing URL
    return `${baseUrl}?text=${encodedMessage}%20${encodedUrl}`;
  };

  const whatsappUrl = generateWhatsAppURL();

  const data = [
    {
      id: "whatsapp",
      label: "Whatsapp",
      href: whatsappUrl,
      // href:
      //   typeof window !== "undefined" &&
      //   `whatsapp://send?text=${value[key]}. Yuk Belajar GRATIS bareng-bareng! https://${window.location.host}/course/${value.slug}&utm_source=whatsapp&utm_medium=organic-button&utm_campaign=${campaign}`,
    },
    {
      id: "linkedin",
      label: "LinkedIn",
      href:
        typeof window !== "undefined" && `https://www.linkedin.com/feed/?shareActive=true&text=${value[key]}. Yuk Belajar GRATIS bareng-bareng! https://${window.location.host}/${path}/${value.slug}&utm_source=linkedin&utm_medium=organic-button&utm_campaign=${campaign}`
    },
    {
      id: "twitter",
      label: "Twitter",
      href:
        typeof window !== "undefined" &&
        `https://twitter.com/intent/tweet?text=${value[key]}. Yuk Belajar GRATIS bareng-bareng! https://${window.location.host}/${path}/${value.slug}&utm_source=twitter&utm_medium=organic-button&utm_campaign=${campaign}`,
    },
    {
      id: "facebook",
      label: "Facebook",
      href:
        typeof window !== "undefined" &&
        `https://www.facebook.com/sharer/sharer.php?u=https://${window.location.host}/${path}/${value.slug}&utm_source=facebook&utm_medium=organic-button&utm_campaign=${campaign}`,
    },
    {
      id: "link",
      label: "Copy Link",
      href:
        typeof window !== "undefined" &&
        `https://${window.location.host}/${path}/${value.slug}`,
      // href:
      //   typeof window !== "undefined" &&
      //   `https://${window.location.host}/course/${value.slug}&utm_source=copy-link&utm_medium=organic-button&utm_campaign=${campaign}`,
      slug: value?.slug
    },
    {
      id: "moodle-link",
      label: "Copy Link",
      href:
        typeof window !== "undefined" &&
        `https://${window.location.host}/local/gokampus/index.php?type=course&slug=${value.slug}`,
      slug: value?.slug
    },
  ];

  return data;
};

function formatString(str: any) {
  let val = str.toString();

  if (val.length == 1) {
    val = `0${val}`;
  }

  return val;
}

export const generatePersonalInfo = (resourse: any) => {
  let objectProfile = {
    addresses: null,
    birthdays: null,
    emailAddresses: null,
    genders: null,
    organizations: null,
    phoneNumbers: null,
  };

  if (resourse.addresses) {
    objectProfile.addresses = resourse.addresses[0]?.formattedValue;
  }

  if (resourse.birthdays) {
    if (
      resourse.birthdays[resourse.birthdays.length - 1]?.date &&
      Object.keys(resourse.birthdays[resourse.birthdays.length - 1]?.date)
        .length == 3
    ) {
      let date = resourse.birthdays[resourse.birthdays.length - 1]?.date;

      objectProfile.birthdays = [
        formatString(date.day),
        formatString(date.month),
        date.year,
      ].join("-");
    } else {
      objectProfile.birthdays = null;
    }
  }

  if (resourse.emailAddresses) {
    objectProfile.emailAddresses = resourse.emailAddresses[0]?.value;
  }

  if (resourse.genders) {
    objectProfile.genders =
      resourse.genders[resourse.genders.length - 1]?.formattedValue;
  }

  if (resourse.organizations) {
    objectProfile.organizations =
      resourse.organizations[0]?.type == "school"
        ? resourse.organizations[0]?.name
        : `${resourse.organizations[0]?.title} || ${resourse.organizations[0]?.name}`;
  }

  if (resourse.phoneNumbers) {
    objectProfile.phoneNumbers =
      resourse.phoneNumbers[0]?.canonicalForm ||
      resourse.phoneNumbers[0]?.value;
  }

  return objectProfile;
};

export const numberWithCommas = (x: number) => {
  let n = x.toString();
  var pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(n)) n = n.replace(pattern, "$1,$2");
  return n;
};

export const formatRating = (x: number | string) => {
  // let n = x.toString().replace(".", ".");
  return x;
};

export const formatThousandsInK = (x: number) => {
  let xStr = x.toString();

  if (xStr.length > 4) {
    return `${xStr.slice(0, -3)}k`;
  } else {
    return x;
  }
};

/// Filter only true flag will be displayed
/// Input: Object = {Business & Entrepreneurship: true, IT & Tech: false}
/// Output: Array = ['Business & Entrepreneurship']
export const formatOptions = (objData: object) => {
  let newObj = [];

  for (var key in objData) {
    if (objData[key] === true)
      // or just if (objData[key])
      newObj.push(key);
  }

  return newObj;
};

export const renameKeys = (properties: object) => {
  const altObj = Object.fromEntries(
    Object.entries(properties).map(([key, value]) =>
      // https://developers.google.com/tag-platform/tag-manager/web/datalayer#:~:text=The%20syntax%20for%20sending%20an,event'%3A%20'event_name'%7D)%3B
      [key.includes("_") ? `${key.replaceAll("_", "-")}` : key, value]
    )
  );
  return altObj;
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const clearSessionAtSpecificTime = () => {
  let now = new Date();
  let targetTime = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(), // Use current day
    23, // Hours (11 PM)
    59, // Minutes (30)
    0 // Seconds
  );

  let timeUntilTargetTime = targetTime.valueOf() - now.valueOf();

  setTimeout(function () {
    // Clear the session storage
    sessionStorage.removeItem("anywhereLength");
    sessionStorage.removeItem("featuredLength");
  }, timeUntilTargetTime);
};

export const isCustomDomain = () => {
  const domain = typeof window !== "undefined" && window.location.hostname;
  return (
    domain &&
    (domain.includes("interagroup") ||
      domain.includes("arwanacitra") ||
      domain.includes("mitra-asa") ||
      domain.includes("emtek") ||
      domain.includes("nobutrial") ||
      domain.includes("nmwtrial") ||
      domain.includes("frontiertrial"))
  );
};

export const truncateDomain = (domain: string) => {
  console.log("truncate")
  let currDomain: string;
  const lengthOfDomain = domain.split(".").length;

  if (lengthOfDomain == 2) {
    //prod : 'gokampus', dev: tenant
    currDomain = domain.split(".")[0];
  } else if (lengthOfDomain == 3) {
    // subdomain tenant
    currDomain = process.env.NODE_ENV == 'production' ? domain.split(".")[0] : domain.split(".")[1];
  } else if (lengthOfDomain == 4) {
    // subdomain moodle tenant
    currDomain = domain.split(".")[1];
  } else if (lengthOfDomain == 1) {
    currDomain = null;
  }

  return currDomain;
};

export const sanitizeLinkOnHTML = (str: string) => {
  return str.replace(/<a\b[^>]*>/i, "").replace(/<\/a>/i, "");
};

export const randomizeMinMax = (min,max) => {
  // Generate a random number between min and max (exclusive).
  const randomNumber = Math.random() * (max - min) + min;

  // Return the random number, rounded down to the nearest integer.
  return Math.floor(randomNumber);
};

export const capitalizeEveryWord = (str) => {
  return str.replace(/\w\S*/g, function(txt){
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}


export const parseTokenSha1 = (str: string) => {
  return sha1(str);
};
import { paths } from "./__generatedApiSpec";
import { Fetcher, Middleware } from "openapi-typescript-fetch";
import {
  ApiResponse,
  OpArgType,
  OpenapiPaths,
  OpErrorType,
  OpReturnType,
} from "openapi-typescript-fetch/dist/cjs/types";
import React, { useContext, useEffect, useState } from "react";
import { CONST } from "./const";
import { isBrowser } from "./ssr";
import { useToastStore } from "components/Toast/toastStore";

export const apiFetcher = Fetcher.for<paths>();

// if apiFetcher return 401, delete token in localstorage, publish token change event to authStore
const auth: Middleware = async (url, init, next) => {
  const response = await next(url, init);
  // auth interceptor
  if (response.status == 401) {
    // only in browser
    if (isBrowser() && window?.localStorage?.getItem("token")) {
      window?.localStorage?.removeItem("token");
      window.dispatchEvent(new Event("tokenChange"));
    }
  }

  return response;
};

// global configuration
apiFetcher.configure({
  baseUrl: isBrowser()
    ? "/api/gk" // api proxy
    : CONST.BASE_API_URL,
  init: {
    headers: {
      Authorization:
        isBrowser() && window?.localStorage?.getItem("token")
          ? "Bearer " + window?.localStorage?.getItem("token")
          : undefined,
    },
  },
  use: [auth], // middlewares
});

interface Paths extends OpenapiPaths<paths> {}

let apiCache: Record<string, any> = {};

export const ApiContext = React.createContext<{
  __apiData: Record<string, any>;
}>({ __apiData: {} });

export type ServerApiFetcherResponse = {
  __apiData?: Record<string, any>;
};

const generateCacheKey = <P extends keyof Paths, M extends keyof Paths[P]>(
  method: M,
  path: P,
  additionalKey: string = ""
) => {
  return `${String(method)}${path}${additionalKey}`;
};

// fetcher on server, use this on getServerSideProps
export const serverApiFetcher = async <
  P extends keyof Paths,
  M extends keyof Paths[P]
>(
  method: M,
  path: P,
  params?: OpArgType<paths[P][M]>,
  additionalKey?: string,
  init?: RequestInit
): Promise<{ props: ServerApiFetcherResponse }> => {
  try {
    const fetcher = apiFetcher.path(path).method(method).create();

    const { data } = await fetcher(params, init);

    const cacheKey = generateCacheKey(method, path, additionalKey);

    return {
      props: {
        __apiData: {
          [cacheKey]: data,
        },
      },
    };
  } catch (err) {
    // custom response props by error status
    if (err?.status == 403) {
      return { props: { ...err?.data, status: err?.status } };
    } else if (err?.status == 404) {
      //@ts-ignore
      return { props: { status: err?.status } };
    } else {
      return { props: {} };
    }
  }
};

// hooks, use in client side

export const useApi = <P extends keyof Paths, M extends keyof Paths[P]>(
  method: M,
  path: P,
  additionalKey: string = "" // additional key like query path
) => {
  const toast = useToastStore();
  const cacheKey = generateCacheKey(method, path, additionalKey);

  // console.log("0 cacheKey", cacheKey);
  // console.log("0 additionalKey", additionalKey);

  const __apiData = useContext(ApiContext).__apiData;

  // console.log("__apiData", __apiData);

  if (__apiData) {
    apiCache = { ...apiCache, ...__apiData };
  }

  // console.log("1 apiCache", apiCache);
  // console.log("2 __apiData", __apiData);

  const cachedData = apiCache[cacheKey];

  // console.log("3 cache", cachedData);

  const [isLoading, setLoading] = useState(false);
  const [hasFetched, setHasFetched] = useState(cachedData ? true : false);

  const [data, setData] = useState<null | OpReturnType<paths[P][M]>>(
    cachedData ? cachedData : null
  );

  const [error, setError] = useState<null | ApiResponse<
    OpErrorType<paths[P][M]>
  >>(null);

  const fetcher = apiFetcher.path(path).method(method).create();

  const doFetch = (
    arg: OpArgType<paths[P][M]>,
    opt?: {
      init?: RequestInit;
      onSuccess?: (data: OpReturnType<paths[P][M]>) => void;
      onError?: (err: any) => void;
      skipCache?: boolean;
    }
  ) => {
    setLoading(true);
    setHasFetched(true);
    const getToken = () => localStorage?.getItem("token");

    const init = getToken()
      ? opt?.init
        ? {
            ...opt?.init,
            headers: {
              ...opt?.init.headers,
              authorization: `Bearer ${getToken()}`,
            },
          }
        : {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
      : undefined;

    fetcher(arg, init)
      .then((res) => {
        setData(res.data);

        if (!opt?.skipCache) {
          // set cache
          apiCache[cacheKey] = res.data;
        }
        setError(null);
        if (opt?.onSuccess) {
          opt.onSuccess(res?.data);
        }
      })
      .catch((err) => {
        setError(err);
        setData(null);

        // temp hidden cause by error message not usefull
        // if (err?.data?.message) {
        //   if (err?.status != 401) {
        //     toast.showToast({
        //       type: "danger",
        //       message: err?.data?.message,
        //     });
        //   }
        // }

        // validate toaster when code resp is 409
        // to show toast on LMS error
        if ([409].includes(err?.status)) {
          toast.showToast({
            type: "danger",
            message: err?.data?.message,
          });
        }

        if (opt?.onError) {
          opt.onError(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const initialFetch = (
    arg: OpArgType<paths[P][M]>,
    opt?: {
      init?: RequestInit;
      onSuccess?: (data: OpReturnType<paths[P][M]>) => void;
      onError?: (err: any) => void;
    }
  ) => {
    if (!data) {
      doFetch(arg, opt);
    }
  };

  if (isBrowser() && CONST.IS_DEV) {
    try {
      (window as any).__apiCache = apiCache;
    } catch (e) {
      console.error(e);
    }
  }

  return {
    hasFetched,
    isLoading,
    data,
    error,
    doFetch,
    fetcher,
    initialFetch,
    apiCache,
  };
};

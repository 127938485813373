import { useApi } from "lib/apiFetcher";

export const useUserGetProfile = () => useApi("get", "/profile");

export const useUserGetInterests = () => useApi("get", "/user-interest");

export const useUserStoreInterests = () => useApi("post", "/user-interest");

//@ts-ignore
export const useUserPreference = () => useApi("post", "/tenants/user-preference");

import createStore from "zustand";

type ThemeType = "dark" | "light";

export type SystemStore = {
  allThemes: any[];
  theme: ThemeType;

  // actions
  setTheme: (t: string) => void;
  setAllTheme: (item: any) => void;
};

export const useSystemStore = createStore<SystemStore>((set) => ({
  // state
  allThemes: [
    {
      id: "light",
      icon: "light",
      active: true,
    },
    {
      id: "dark",
      icon: "dark",
      active: false,
    },
    {
      id: "system",
      icon: "system",
      active: false,
    },
  ],
  theme: "light",

  // actions
  setTheme(a: ThemeType) {
    set({ theme: a });
  },
  setAllTheme(a: any) {
    set({ allThemes: a });
  },
}));

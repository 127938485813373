import createStore from "zustand";

export type ToastType = "success" | "info" | "danger";

export type ToastItem = {
  type?: ToastType;
  message?: string;
};

export type ToastStore = {
  // state
  current: ToastItem | null;

  // actions
  showToast: (item: ToastItem) => void;
  hideAllToast: () => void;
};

export const useToastStore = createStore<ToastStore>((set) => ({
  // state
  current: null,

  // actions
  showToast(item) {
    set({ current: item });

    setTimeout(() => {
      set({ current: null });
    }, 3000);
  },
  hideAllToast() {
    set({ current: null });
  },
}));

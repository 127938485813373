
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { AppProps } from "next/app";
import App from "next/app";
import { useLoginByMoodle } from "modules/Auth/authHooks";
import "../styles/globals.css";
import localFont from "@next/font/local";
import { DefaultMeta } from "modules/Meta/DefaultMeta";
import { ApiContext } from "lib/apiFetcher";
import NextNProgress from "nextjs-progressbar";
import { ToastWrapper } from "components/Toast/ToastWrapper";
import { AlgoliaContext } from "lib/algolia";
import { useEffect, useState } from "react";
import { useSetUserAmplitudeWhenAuthChange } from "lib/amplitude";
import { setCookie, getCookie, getQueryStringParams, setParamsAsCookies, truncateDomain, } from "lib/helper";
import { useAuthStore } from "modules/Auth/authStore";
import { NextPageContext } from "next";
import { IsDesktopContext, isDeviceDesktop } from "lib/device";
import { IsBusinessContext, IsSubdomainContext, IsTenantContext, IsGateContext, isBusinessURL, isGateURL, isTenantURL, IsGateProvinceContext, isMalukuURL, } from "lib/host";
import { useGetSubdomainTenant } from "modules/Tenant/homeTenantApi";
import FloatingButton from "modules/Global/FloatingButton";
import { useSystemStore } from "components/PageHeader/systemStore";
const CircularFont = localFont({
    src: [
        { path: "../fonts/circular-light.woff2", weight: "300" },
        { path: "../fonts/circular-medium.woff2", weight: "500" },
        { path: "../fonts/circular-black.woff2", weight: "700" },
    ],
});
const MyApp = ({ Component, pageProps, router }: AppProps) => {
    useSetUserAmplitudeWhenAuthChange();
    useLoginByMoodle();
    const [theme, setTheme, setAllTheme, allThemes] = useSystemStore((state) => [
        state.theme,
        state.setTheme,
        state.setAllTheme,
        state.allThemes,
    ]);
    const [isSubdomainTenant, setSubdomainTenant] = useState(false);
    const loginState = useAuthStore((state) => state.loginState);
    let referral = typeof router.query.referral == "string"
        ? router.query.referral
        : getCookie("ref_code_gk") &&
            getCookie("ref_code_gk") !== "null" &&
            getCookie("ref_code_gk");
    /// Add / Remove dark class in html
    useEffect(() => {
        const script = document.createElement("script");
        if (localStorage.getItem("theme") === "dark") {
            script.innerHTML = "document.documentElement.classList.add('dark');";
        }
        else {
            script.innerHTML = "document.documentElement.classList.remove('dark');";
        }
        document.body.appendChild(script);
        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, [theme]);
    useEffect(() => {
        // Load Theme from local storage
        const savedTheme = localStorage.getItem("theme");
        if (savedTheme) {
            // Set initial color scheme
            // @ts-ignore
            setTheme(savedTheme);
            setAllTheme(allThemes.map((x) => x.id === savedTheme ? { ...x, active: true } : { ...x, active: false }));
        }
        else {
            let defaultTheme = "light";
            // Save Theme to local storage
            localStorage.setItem("theme", defaultTheme);
            // Change Theme in local variable
            setTheme(defaultTheme);
            setAllTheme(allThemes.map((x) => x.id === defaultTheme
                ? { ...x, active: true }
                : { ...x, active: false }));
        }
    }, []);
    const queries = router.query && router.query.hasOwnProperty("utm_source")
        ? Object.fromEntries(Object.entries(router.query).filter(([key, value]) => key.includes("utm")))
        : {};
    const uri = router.query &&
        ["/login", "/register"].includes(router.pathname) &&
        router.query.url
        ? router.query.url
        : null;
    // ========== Referral Code ========== //
    useEffect(() => {
        // any spesific cookie and different with current referral
        const isExistCookie = ["null", referral].every((a) => a !== getCookie("ref_code_gk"));
        // no have spesific cookie and have referral query
        const isUseRefQuery = !getCookie("ref_code_gk") && referral;
        if (loginState == "NOT_LOGIN" && referral) {
            if (isExistCookie || isUseRefQuery) {
                setCookie("ref_code_gk", referral, 7);
            }
        }
    });
    // ========== Referral Code ========== //
    // ========== UTM ========== //
    useEffect(() => {
        try {
            let queriesURI = uri
                ? //@ts-ignore
                    getQueryStringParams(decodeURIComponent(uri).split("?")[1])
                : {};
            setParamsAsCookies(queries, queriesURI);
        }
        catch (err) {
            console.log(err);
        }
    });
    // ========== UTM ========== //
    //@ts-ignore
    const getSubdomainAPI = useGetSubdomainTenant();
    const subdomain = pageProps?.subdomain;
    useEffect(() => {
        if (subdomain && !["tenant", "gokampus"].includes(subdomain)) {
            getSubdomainAPI.doFetch({
                slug: window.location.hostname === "e-learning.bluebirdgroup.com"
                    ? "bluebird"
                    : subdomain,
            }, {
                onSuccess(data) {
                    //@ts-ignore
                    setSubdomainTenant(data?.status);
                },
                onError(err) {
                    router.push("/404");
                    setSubdomainTenant(false);
                },
            });
        }
        else {
            setSubdomainTenant(false);
        }
    }, [subdomain]);
    // ========== fetch api to check if subdomain registered ========== //
    const isHideFeedback = router.pathname === "/course/[courseSlug]" && !pageProps?.isDesktop;
    // ========== Service Worker ========== //
    useEffect(() => {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker
                .register("/service-worker.js")
                .then((registration) => console.log("scope is: ", registration.scope))
                .catch((err) => console.log("SW registration failed: ", err));
        }
    }, []);
    // ========== Service Worker ========== //
    return (<>
      <NextNProgress color={theme == "dark" ? "#A184DD" : "#551FC1"} options={{
            showSpinner: false,
        }} transformCSS={(css) => {
            return <style>{css.replaceAll("\n", "")}</style>;
        }}/>
      <div className={[
            CircularFont.className,
            "text-purple-80 font-medium relative bg-white",
        ].join(" ")}>
        <IsDesktopContext.Provider value={pageProps?.isDesktop}>
          <IsTenantContext.Provider value={pageProps?.isTenant}>
            <IsBusinessContext.Provider value={pageProps?.isBusiness}>
              <IsGateContext.Provider value={pageProps?.isGate}>
                <IsGateProvinceContext.Provider value={pageProps?.isGateProvince}>
                  <IsSubdomainContext.Provider value={isSubdomainTenant}>
                    <ApiContext.Provider value={{ __apiData: pageProps?.__apiData || {} }}>
                      <AlgoliaContext.Provider value={{
            __algoliaData: pageProps?.__algoliaData || {},
        }}>
                        <DefaultMeta withDefault={true}/>
                        <Component {...pageProps}/>
                        <ToastWrapper />
                        {/* {!(["/login", "/register", "/404", "/500"].includes(router.pathname) || isHideFeedback) && <FloatingButton />} */}
                      </AlgoliaContext.Provider>
                    </ApiContext.Provider>
                  </IsSubdomainContext.Provider>
                </IsGateProvinceContext.Provider>
              </IsGateContext.Provider>
            </IsBusinessContext.Provider>
          </IsTenantContext.Provider>
        </IsDesktopContext.Provider>
      </div>
    </>);
};
MyApp.getInitialProps = async (appContext) => {
    let isDesktop = false;
    let isTenant = false;
    let isBusiness = false;
    let isGate = false;
    let isGateProvince = false;
    let subdomain: string;
    const appProps = await App.getInitialProps(appContext);
    try {
        if (appContext.ctx.req) {
            subdomain = truncateDomain(appContext.ctx.req?.headers?.["host"]);
            isDesktop = isDeviceDesktop(appContext.ctx.req?.headers?.["user-agent"]);
            isTenant = isTenantURL(appContext.ctx.req?.headers?.["host"]);
            isBusiness = isBusinessURL(appContext.ctx.req?.headers?.["host"]);
            isGate = isGateURL(appContext.ctx.req?.headers?.["host"]);
            isGateProvince = isMalukuURL(appContext.ctx.req?.headers?.["host"]);
        }
        else {
            subdomain = truncateDomain(window.location.hostname);
            isDesktop = isDeviceDesktop(window.navigator.userAgent);
            isTenant = isTenantURL(window.location.hostname);
            isBusiness = isBusinessURL(window.location.hostname);
            isGate = isGateURL(window.location.hostname);
            isGateProvince = isMalukuURL(window.location.hostname);
        }
    }
    catch { }
    return {
        pageProps: {
            ...appProps,
            isDesktop,
            isTenant,
            isBusiness,
            isGate,
            subdomain,
            isGateProvince,
        },
    };
};
const __Next_Translate__Page__1932e99ca09__ = MyApp;

    export default __appWithI18n(__Next_Translate__Page__1932e99ca09__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  
export const CONST = {
  IS_DEV: process.env.NEXT_PUBLIC_IS_DEV === "true", // if develop is true, if production is false, dont change this const in branch develop/master
  BASE_API_URL: process.env.NEXT_PUBLIC_BASE_API_URL,
  IMAGE_HOSTS: process.env.NEXT_PUBLIC_IMAGE_HOSTS,
  GOOGLE_CLIENT_ID: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
  ALGOLIA_APP_ID: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  ALGOLIA_API_KEY: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  ALGOLIA_SEARCH_KEY: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_KEY,
  ALGOLIA_ANALYTICS_KEY: process.env.NEXT_PUBLIC_ALGOLIA_ANALYTICS_KEY,
  ALGOLIA_COURSE_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_COURSE_INDEX,
  AMPLITUDE_API_KEY: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
  MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  GA_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
  GTM_ID: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER,
  MUX_ENV_KEY: process.env.NEXT_PUBLIC_MUX_ENV_KEY,
  MUX_PLAYER_NAME: process.env.NEXT_PUBLIC_MUX_PLAYER_NAME,
  ALGOLIA_SEARCH_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX,
  ALGOLIA_SEARCH_SUGGESTIONS:
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_SUGGESTIONS_INDEX,
  ALGOLIA_BUNDLE_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_BUNDLE_INDEX,
  ALGOLIA_B2B_INDEX: process.env.NEXT_PUBLIC_ALGOLIA_B2B_INDEX,
  SOULMACHINES_APIKEY: process.env.NEXT_PUBLIC_SOULMACHINES_APIKEY,
  HEYGEN_APIKEY_TRIAL: process.env.NEXT_PUBLIC_HEYGEN_APIKEY_TRIAL,
  HEYGEN_APIKEY: process.env.NEXT_PUBLIC_HEYGEN_APIKEY,
  OPENAI_APIKEY: process.env.NEXT_PUBLIC_OPENAI_APIKEY,
  HEYGEN_STATUS: process.env.NEXT_PUBLIC_HEYGEN_STATUS,
};

import createStore from "zustand";
import { paths } from "lib/__generatedApiSpec";
import { OpReturnType } from "openapi-typescript-fetch";

export type UserData = OpReturnType<paths["/profile"]["get"]>["data"];

type LoginState = "IDLE" | "LOADING" | "NOT_LOGIN" | "LOGIN";

type InterestData = OpReturnType<paths["/user-interest"]["get"]>["data"];

type TenantData = OpReturnType<paths["/tenants/{slug}"]["get"]>;

export type AuthStore = {
  // state
  loginState: LoginState;
  currentUser: UserData | null;
  interests: InterestData;
  isRecommendationChanged: boolean;
  tenantData: TenantData | null;

  // actions
  setLoading: () => void;
  setLogin: (user: UserData) => void;
  setNotLogin: () => void;
  setInterests: (interests: InterestData) => void;
  setRecommendationChanged: (b: boolean) => void;
  setTenantData: (tenant: TenantData) => void;
};

export const useAuthStore = createStore<AuthStore>((set) => ({
  // state
  loginState: "IDLE",
  currentUser: null,
  interests: [],
  isRecommendationChanged: false,
  tenantData: null,

  // actions
  setLoading() {
    set({ loginState: "LOADING" });
  },
  setLogin(currentUser) {
    set({
      loginState: "LOGIN",
      currentUser,
    });
  },
  setNotLogin() {
    set({
      loginState: "NOT_LOGIN",
      currentUser: null,
    });
  },
  setInterests(interests) {
    set({
      interests,
    });
  },
  setRecommendationChanged(b) {
    set({
      isRecommendationChanged: b,
    });
  },
  setTenantData(tenant){
    set({tenantData: tenant})
  }
}));

module.exports = {
  locales: ["id", "en"],
  defaultLocale: "id",
  localeDetection: false,
  loadLocaleFrom: (locale, ns) => {
    const m = require(`/locales/${locale}/${ns}.json`);
    return Promise.resolve(m);
  },
  pages: {
    "*": ["common", "tags"],
    "/": ["home", "course"],
    "/course/[courseSlug]": ["course"],
    "/my-class": ["course"],
    "/transactions/[transactionId]": ["course"],
  },
};

// server only

import { CONST } from "./const";

import algoliasearch from "algoliasearch";
import {
  SearchOptions,
  SearchResponse,
} from "@algolia/client-search/dist/client-search";
import React, { useContext, useState } from "react";
import { isBrowser } from "./ssr";
import gerbangsari from "data/tenants/gerbangsari.json"

const algoliaClient = algoliasearch(
  CONST.ALGOLIA_APP_ID,
  CONST.ALGOLIA_SEARCH_KEY
);

const algoliaCourseIndex = algoliaClient.initIndex(CONST.ALGOLIA_COURSE_INDEX);
const algoliaSearchIndex = algoliaClient.initIndex(CONST.ALGOLIA_SEARCH_INDEX);
const algoliaBundleIndex = algoliaClient.initIndex(CONST.ALGOLIA_BUNDLE_INDEX);
const algoliaB2BIndex = algoliaClient.initIndex(CONST.ALGOLIA_B2B_INDEX);

export type CourseHit = {
  slug?: string;
  name?: string;
  description?: string;
  image_cover_url?: string;
  format?: string;
  category?: string;
  institution?: string;
  level?: string;
  duration?: number;
  original_price?: number;
  discounted_price?: number;
  skills?: string[];
  tags?: string[];
  is_bussines?: boolean;
};

export const searchCourse = (q: string, opt?: SearchOptions) =>
  algoliaCourseIndex.search<CourseHit>(q, opt);

export const searchCourseByName = (q: string, opt?: SearchOptions) =>
  algoliaSearchIndex.search<CourseHit>(q, opt);

export const searchCourseBundle = (q: string, opt?: SearchOptions) =>
  algoliaBundleIndex.search<CourseHit>(q, opt);

export const searchCourseB2B = (q: string, opt?: SearchOptions) =>
  algoliaB2BIndex.search<CourseHit>(q, opt);

export const algoliaFetcher = async ({
  q = "",
  filters = "",
  page = 0,
  hitsPerPage = 1000,
  isKey = false,
}: {
  q?: string;
  page?: number;
  filters?: string;
  hitsPerPage?: number;
  isKey?: boolean;
}): Promise<{ data?: SearchResponse<CourseHit>; error?: any }> => {
  try {
    let objects = {
      filters: filters,
      facets: ["institution", "category", "tags"],
      page: page,
      hitsPerPage,
    };
    const data = isKey
      ? await searchCourseByName(q, objects)
      : await searchCourse(q, objects);
    return { data };
  } catch (error) {
    return { error };
  }
};

export const AlgoliaContext = React.createContext<{
  __algoliaData: Record<string, SearchResponse<CourseHit>>;
}>({ __algoliaData: {} });

export type ServerAlgoliaFetcherResponse = {
  __algoliaData?: Record<string, SearchResponse<CourseHit>>;
};

const generateCacheKey = (
  q: string = "",
  page: number = 0,
  filters: string = "",
  hitsPerPage: number = 1000
) => {
  return [q, page, filters, hitsPerPage].join(",");
};

// fetcher on server, use this on getServerSideProps
export const serverAlgoliaFetcher = async (
  q?: string,
  page?: number,
  filters?: string,
  hitsPerPage?: number,
  isKey?: boolean
): Promise<{ props: ServerAlgoliaFetcherResponse }> => {
  try {
    const { data } = await algoliaFetcher({
      q,
      filters,
      page,
      hitsPerPage,
      isKey,
    });

    const cacheKey = generateCacheKey(q, page, filters, hitsPerPage);

    return {
      props: {
        __algoliaData: {
          [cacheKey]: data,
        },
      },
    };
  } catch {
    return { props: {} };
  }
};

export const useAlgoliaFetcher = () => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<null | SearchResponse<CourseHit>>(null);
  const [error, setError] = useState<null | any>(null);

  const doFetch = async (
    q: string = "",
    page: number = 0,
    filters: string = ""
  ) => {
    setLoading(true);
    algoliaFetcher({ q, page, filters })
      .then((res) => {
        if (res.data) {
          setData(res.data);
          setError(null);
        } else {
          setError(res.error);
          setData(null);
        }
      })
      .catch((err) => {
        setData(null);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    isLoading,
    data,
    error,
    doFetch,
  };
};

export const useAlgoliaData = (
  q: string = "",
  page: number = 0,
  facetFilters: string = ""
): null | SearchResponse<CourseHit> => {
  const algoliaData = useContext(AlgoliaContext).__algoliaData;

  if (isBrowser() && CONST.IS_DEV) {
    try {
      (window as any).__algoliaData = algoliaData;
    } catch (e) {
      console.error(e);
    }
  }

  return algoliaData[generateCacheKey(q, page, facetFilters)];
};

export const generateIndexName = (referral: string) => {
  /// Condition for some tenant
  let finalReferral = referral
  if(referral == "demodesa"){
    finalReferral = "desa";
  }else if(gerbangsari.some((a) => a === referral)){
    finalReferral = "gerbangsari";
  }

  if(!CONST.IS_DEV) {
    return `prod_tenant_${finalReferral}_index`
  } else {
    return `dev_tenant_${finalReferral}_index`
  }
}

/// Unusued
export const isPartOfSubdomain = (referral: string) => {
  return gerbangsari.some((a) => a === referral)
}

// import { AmplitudeBrowser } from "@amplitude/analytics-browser/lib/esm/browser-client";
import { useEffect, useState } from "react";
// import {
//   init,
//   track,
//   identify,
//   Identify,
//   setUserId,
// } from "@amplitude/analytics-browser";
import { CONST } from "./const";
import { useAuthStore } from "modules/Auth/authStore";
import { ActionTracker, ScreenTracker } from "./__generatedTracker";

import mixpanel from "mixpanel-browser";
import { renameKeys } from "./helper";
import { isGateURL } from "./host";

mixpanel.init(CONST.MIXPANEL_TOKEN);

export { mixpanel };

// init(CONST.AMPLITUDE_API_KEY, null, { minIdLength: 1 });

// const prefix = "gokampus-";

export const useSetUserAmplitudeWhenAuthChange = () => {
  const { loginState, currentUser } = useAuthStore((state) => ({
    loginState: state.loginState,
    currentUser: state.currentUser,
  }));

  useEffect(() => {
    const isGate = isGateURL(window.location.hostname)
    if (loginState == "LOGIN" || loginState == "NOT_LOGIN") {
      // const identifyObj = new Identify();
      if (loginState == "LOGIN") {
        // setUserId(`${currentUser.id}`);
        // identifyObj.set("is_logged_in", true);
        // identifyObj.set("referral", currentUser.referral);

        mixpanel.identify(currentUser?.id ?? "");

        mixpanel.register({
          is_logged_in: true,
          referral: currentUser?.referral ?? "",
          from_app: isGate,
        });
      } else {
        // setUserId("");
        // identifyObj.set("is_logged_in", false);

        mixpanel.register({
          is_logged_in: false,
        });
      }
      // identify(identifyObj);
    }
  }, [loginState]);
};

export const trackEvent = (tracker: ScreenTracker | ActionTracker) => {
  console.log("push to Mixpanel ~~", {
    ...tracker,
  });

  // mixpanel.track(tracker.event, tracker.property);

  if (typeof window !== "undefined" && tracker.property) {
    let properties = renameKeys(tracker.property);
    // @ts-ignore
    window.dataLayer.push({
      event: tracker.event,
      ...properties,
    });
  }

  // return track(tracker.event, tracker.property);
  mixpanel.track(tracker.event, tracker.property);
};

export const useTrackScreen = (tracker: ScreenTracker) => {
  const { loginState } = useAuthStore((state) => ({
    loginState: state.loginState,
  }));

  useEffect(() => {
    if (loginState == "LOGIN" || loginState == "NOT_LOGIN") {
      setTimeout(() => {
        trackEvent(tracker);
      }, 1);
    }
  }, [loginState]);
};
